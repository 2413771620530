export const ERROR_CODES = {
  AUTH: {
    GENERIC_SERVER: -1000,
    REQ_VALIDATION: -2000,
    DB_ERROR: -3000,
    REDIS_ERROR: -4000,
    LOGIN_CREDENTIALS: 1001,
    ACCOUNT_DISABLED: 1002,
    EMAIL_UNVERIFIED: 1003,
    GOOGLE_CAPTCHA: 1004,
    REGISTER_CREDENTIALS: 1005,
    DUPLICATE_EMAIL: 1006,
    INCORRECT_REFERRAL: 1007,
    INVALID_REFERRAL: 1008,
    NETWORK_ERROR: 1009,
    RESPONSE_ERROR: 1010,
    INVALID_PHONE_OTP: 1011,
    INVALID_EMAIL_OTP: 1012,
    INVALID_EMAIL: 1013,
    CREATE_APPLICANT_FAILED: 1014,
    AUTHENTICATION_ERROR: 1015,
    USER_NOT_FOUND: 1016,
    NO_PERMISSION: 1017,
    INVALID_ISS: 1018,
    INVALID_AUD: 1019,
    APPLE_AUTHKEY: 1020,
    APPLE_TOKEN_REQUEST: 1021,
    INVALID_APPLE_CODE: 1022,
    INVALID_APPLE_TOKEN: 1023,
    INVALID_GRANT: 1024,
    INVALID_GOOGLE_TOKEN: 1025,
    EMAIL_NOT_FOUND: 1026,
    DISABLED_WITHDRAW_FIAT: 1027,
    DISABLED_WITHDRAW_CRYPTO: 1028,
    DISABLED_TRADE: 1029,
    APPLICANT_NOT_FOUND: 1030,
    TOTP_BIND: 1031,
    TOTP_GENERATE: 1032,
    TOTP_ENCRYPT: 1033,
    OTP_GENERATE: 1034,
    INVALID_ACTION: 1035,
    TOTP_INACTIVATED: 1036,
    TOTP_DECRYPT: 1037,
    TOTP_VALIDATION: 1038,
    DUPLICATE_PHONE: 1039,
    MISSING_PHONE_EMAIL: 1040,
    EXCLUSIVE_PHONE_EMAIL: 1041,
    PHONE_NOT_FOUND: 1042,
    PHONE_SETUP: 1043,
    UPDATE_CREDENTIALS: 1044,
    INCORRECT_OLD_PASSWORD: 1045,
    CREATE_STREAM_KEY: 1046,
    DELETE_STREAM_KEY: 1047,
    FETCH_STREAM_KEY: 1048,
    REFRESH_STREAM_KEY: 1049,
    INVALID_USERID: 1050,
  },
  FIAT: {
    INVALID_LOGIN_CREDENTIALS: 2001,
    ACCOUNT_DISABLED_FOR_LOGIN: 2002,
    EMAIL_UNVERIFIED: 2003,
    USER_ALREADY_HAS_MORE_THAN_10_ACCOUNTS: 2004,
    ACCOUNTS_ID_CARD_NOT_FOUND: 2005,
    FAIL_TO_QUERY_CREATE_USER_BANK: 2006,
    ACCOUNT_IS_REGISTERED_WITH_USER: 2007,
    ACCOUNT_IS_REGISTERED_WITH_A_DIFFERENT_ACCOUNT: 2008,
    FAILED_TO_DETECT_AUTHORIZED_PAYMENT: 2009,
    UNABLE_TO_DETECT_CURLEC_EVENT: 2010,
    FAIL_TO_GENERATE_REF_ID: 2011,
    UNABLE_TO_CREATE_USER: 2012,
    INVALID_ID: 2013,
    SYMBOL_NOT_FOUND_IN_FIAT_MODULE: 2014,
    UNABLE_TO_CREATE_DEPOSIT_PROOF: 2015,
    USERS_WITHDRAWAL_IS_TEMPORARILY_DISABLED: 2016,
    AMOUNT_IS_BELOW_MINIMUM_WITHDRAWAL_AMOUNT: 2017,
    AMOUNT_IS_ABOVE_MAXIMUM_WITHDRAWAL_AMOUNT: 2018,
    USER_BANK_NOT_FOUND_IN_FIAT_MODULE: 2019,
    USER_BANK_IS_NOT_VERIFIED_YET: 2020,
    REFERENCE_NUMBER_DOES_NOT_EXIST: 2021,
    FIAT_TRANSACTION_NOT_FOUND: 2022,
    UNABLE_TO_CREATE_WITHDRAWAL: 2023,
    UNABLE_TO_CREATE_WITHDRAWAL_FEE: 2024,
    ERROR_GENERATING_RJWT: 2025,
    ERROR_VALIDATING_ACCESS_TOKEN: 2026,
    REDIS_SERVER_ERROR: 2027,
    UNABLE_TO_PARSE_TRANSACTION_DATE_TIME: 2028,
    UNABLE_TO_MATCH_NAME_FOR_BANK_VERIFICATION: 2029,
    FAILED_TO_SEARCH_FOR_USER_BANK: 2030,
    UNABLE_TO_UPDATE_WITHDRAWAL: 2031,
    UNABLE_TO_UPDATE_TRANSACTION: 2032,
    UPDATE_SPOTBALANCE_ERROR: 2033,
    UNABLE_TO_PARSE_USER_VAN: 2034,
    WITHDRAWAL_HAS_ALREADY_BEEN_PROCESSED: 2035,
    UNABLE_TO_UPDATE_COMPLETED_TRANSACTION: 2036,
    UNABLE_TO_UPDATE_COMPLETED_WITHDRAWAL: 2037,
    INVALID_TOTP: 2038,
    INVALID_OTP: 2039,
    FAILED_TO_CREATE_CURLEC_ORDER: 2040,
    USER_HAS_REACH_WITHDRAWAL_LIMIT: 2041,
    CANNOT_FIND_TRANSACTION: 2042,
    TOKEN_NOT_FOUND: 2043,
    STATEMENT_NOT_FOUND: 2044,
    NO_DETAILS_FOR_ANYTHING_OTHER_THAN_DEPOSIT: 2045,
    CANNOT_FIND_FIAT_WITHDRAWAL_TRANSACTION: 2046,
    CANNOT_FIND_CURLEC_TRANSACTION: 2047,
    CANNOT_FIND_BANK_TRANSACTION: 2048,
    NOT_HANDLED_YET: 2049,
    USERID_MISSING: 2050,
    BUSINESS_REGISTRATION_NOT_FOUND: 2051,
    UNABLE_TO_CREATE_FIAT_TRANSACTION: 2052,
    BANK_NOT_FOUND: 2053,
    WITHDRAWAL_NOT_FOUND: 2054,
    WITHDRAWAL_FAIL_INSUFFICIENT_BALANCE: 2055,
    DUIT_NOW_NOT_EXIST: 2056,
    BANK_VERIFICATION_FAIL: 2057,
    DUPLICATE_BANK: 2058
  },
  IDENTITY: {
    ALREADY_APPROVED: 3001,
    INVALID_COUNTRY: 3002,
    INVALID_ID: 3003,
    NO_RECORD_FOUND: 3004,
    RECORD_BEEN_REJECTED: 3005,
    NOT_A_MALAYSIA_CITIZEN_OR_A_RESIDENT: 3006,
    DUPLICATE_IDENTITY_NUMBER: 3007,
    ERROR_MIGRATING_BUSINESS_IDENTITY_DATA: 3008,
    GETSCORECARD_ERROR: 3009,
    GETSCORECARD_RESPONSE_STATUS_CODE_ERROR: 3010,
    MARSHAL_PARAM_ERROR: 3011,
    CALL_OKAYID_ERROR: 3012,
    READ_RESPONSE_BODY_ERROR: 3013,
    UNMARSHAL_RESPONSE_BODY_ERROR: 3014,
    INNOV8TIF_RESPONSE_STATUS_CODE: 3015,
    UNABLE_TO_PASS_LIVENESS_CHECK: 3016,
    MARSHAL_OKAYPASSPORTINPUT_ERROR: 3017,
    REQUEST_OKAYPASSPORT_ERROR: 3018,
    OKAYPASSPORT_RESPONSE_STATUS_ERROR: 3019,
    UPDATE_USER_KYC_ERROR: 3020,
    UPDATE_USER_KYC_ERROR_USER_DOES_NOT_EXIST: 3021,
    INVALID_KYC: 3022,
    UPDATE_BUSINESS_KYC_ERROR: 3023,
    UPDATE_COMMENT_FAIL: 3024,
    FAILED_TO_INITIALIZE_JOURNEY_ID: 3025,
    DOCUMENT_NOT_SUPPORTED: 3026,
    EMPTY_JOURNEYID: 3027,
    DATA_INCOMPLETE: 3028,
    OKAYDOC_VALIDATE_FAIL: 3029,
    ACTION_DENIED: 3030,
    UNABLE_TO_CREATE_USER: 3031,
    ATTACHMENT_EXISTS: 3032,
    ACCOUNT_REGISTERED: 3033,
    UNABLE_TO_REGISTER_ON_THIS_PLATFORM: 3034,
    UNABLE_TO_OBTAIN_DATA: 3035,
    UNABLE_TO_OBTAIN_IMAGE: 3036,
  },
  ORDERBOOK: {
    ORDER_DOES_NOT_BELONG_TO_THE_USER: 4001,
    ERROR_UPDATING_BALANCE: 4002,
    FAILED_TO_CREATE_NEW_ORDER: 4003,
    PAIR_NAME_NOT_FOUND: 4004,
    NEW_TRADING_PAIR_NO_MARKET_PRICE_AVAILABLE_PLEASE_PLACE_A_LIMIT_ORDER_INSTEAD: 4005,
    FILL_OR_KILL_NOT_ENOUGH_VOLUME_TO_FULFILL_THE_ORDER: 4006,
    INVALID_LOT_SIZE: 4007,
    EXCEEDED_ORDER_LIMIT_FOR_PAIR: 4008,
    UNABLE_TO_CREATE_SPOT_BALANCE_LOG_FOR_USERID: 4009,
    UNABLE_TO_CONVERT_PRICE_TO_DECIMAL: 4010,
    REQUEST_FAILED: 4011,
    INTERNAL_ERROR: 4012,
    PAIR_CURRENTLY_DISABLE_FOR_TRADING: 4013,
    FROM_DATE_CANNOT_BE_MORE_THAN_TO_DATE: 4014,
    ORDER_NOT_FOUND: 4015,
    SYMBOL_NOT_FOUND: 4016,
    UNABLE_TO_CONVERT_PAIR_PERCENTAGE_TO_DECIMAL: 4017,
    UNABLE_TO_CONVERT_YESTERDAY_MAX_PRICE_TO_DECIMAL: 4018,
    UNABLE_TO_CONVERT_YESTERDAY_PRICE_TO_DECIMAL: 4019,
    UNABLE_TO_CONVERT_VOLUME_TO_DECIMAL: 4020,
    UNABLE_TO_CONVERT_MAXQTY_TO_DECIMAL: 4021,
    UNABLE_TO_CONVERT_MINQTY_TO_DECIMAL: 4022,
    UNABLE_TO_CONVERT_MINSTEP_TO_DECIMAL: 4023,
    PAIR_NAME_NOT_FOUND_IN_CACHE: 4024,
    INSUFFICIENT_BALANCE: 4025,
    ERROR_UPDATING_ORDERS: 4026,
    ORDER_STATUS_IS: 4027,
    MARKET_ORDERS_MUST_NOT_HAVE_PRICE: 4028,
    INVALID_DECIMAL_PRECISION_FOR_QTY_OR_QUOTE_QTY: 4029,
    STOP_LIMIT_PRICE_MUST_NOT_BE_SET_FOR_MARKET_ORDERS: 4030,
    BUY_MARKET_ORDERS_MUST_HAVE_QUOTE_QTY: 4031,
    MINIMUM_ORDER_VALUE_IS: 4032,
    MAXIMUM_ORDER_VALUE_IS: 4033,
    MINIMUM_ORDER_QTY_SHOULD_BE: 4034,
    MAXIMUM_ORDER_QTY_SHOULD_BE: 4035,
    INVALID_DECIMAL_PRECISION_FOR_PRICE_QTY_OR_QUOTE_QTY: 4036,
    LIMIT_ORDERS_MUST_HAVE_PRICE_AND_QTY: 4037,
    MINIMUM_ORDER_QTY_SHOULD_BE_AND_MAXIMUM_ORDER_QTY_SHOULD_BE: 4038,
    PRICE_IS_TOO_FAR_FROM_MARKET_PRICE: 4039,
    STOP_LIMIT_PRICE_MUST_NOT_BE_SET_FOR_LIMIT_ORDERS: 4040,
    INVALID_STOP_LIMIT_PRICE: 4041,
    POST_ONLY_ERROR_ORDER_WILL_BE_EXECUTED_AS_A_TAKER: 4042,
    REDISPAIRERR: 4043,
    MAX_PRICE: 4044,
    MIN_PRICE: 4045,
    YESTERDAY_CLOSE_PRICE: 4046,
    VOLUME: 4047,
    DECIMAL2STRING: 4048,
    CANDLEERR: 4049,
    FAILED_TO_VALIDATE: 4050,
    BAD_REQUEST: 4051,
    PAIR_NOT_FOUND: 4052,
    FAILED_TO_OBTAIN_PAIR_INFO: 4053,
  },
  PROMOTION: {
    USER_KYC_NOT_APPROVED: 6001,
    ACCOUNT_ALREADY_PASSED_30_DAYS_KYC: 6002,
    ACCOUNT_IS_NOT_KYC_VERIFIED_YET: 6003,
    ACCOUNT_ALREADY_CLAIMED_A_REFERRAL_AFFILIATE_REWARD: 6004,
    REFERRAL_REWARD_HAS_ALREADY_BEEN_CLAIMED: 6005,
    REFERRAL_REWARD_HAS_ALREADY_EXPIRED: 6006,
    REFERRAL_REWARDS_NOT_AVAILABLE_FOR_GLOBAT_PLATFORM: 6007,
    INVALID_CODE_ENTERED: 6008,
    USER_ALREADY_CLAIMED_REFERRAL_CODE: 6009,
    CANNOT_ENTER_YOUR_OWN_REFERRAL_CODE: 6010,
    REFERRALS_ONLY_IN_MALAYSIA_PLATFORM: 6011,
    BUSINESS_ACCOUNT_ARE_NOT_ELIGIBLE_FOR_REFERRAL: 6012,
    CANNOT_ENTER_REFERRAL_CODE_REGISTERED_AFTER_YOUR_ACCOUNT: 6013,
    CANNOT_ENTER_YOUR_OWN_AFFILIATE_CODE: 6014,
    CANNOT_ENTER_DOWNLINE_AFFILIATE_CODE: 6015,
    CANNOT_ENTER_AFFILIATE_CODE_REGISTERED_AFTER_YOUR_ACCOUNT: 6016,
    USER_ALREADY_CLAIMED_TRX: 6017,
    PROMO_CODE_EXPIRED: 6018,
    INVALID_PROMO_CODE: 6019,
    PROMO_CODE_ALREADY_CLAIMED: 6020,
    DEPOSIT_TASK_NOT_DONE: 6021,
    DEPOSIT_REWARD_HAS_ALREADY_BEEN_FULLY_CLAIMED: 6022,
    DEPOSIT_REWARD_EXPIRED: 6023,
    PURCHASE_TASK_NOT_DONE: 6024,
    PURCHASE_REWARD_HAS_ALREADY_BEEN_FULLY_CLAIMED: 6025,
    PURCHASE_REWARD_EXPIRED: 6026,
    PURCHASE_REWARD_STILL_LOCKED: 6027,
    DOWNLINE_HAS_ALREADY_CLAIM_DEPOSIT_REWARD: 6028,
    UPLINE_HAS_ALREADY_CLAIM_DEPOSIT_REWARD: 6029,
    TOKEN_TKX_NOT_FOUND: 6030,
    TOKEN_USD_NOT_FOUND: 6031,
    CAMPAIGN_NOT_FOUND: 6032,
    SELECTED_MORE_THAN_500_USERS: 6033,
  },
  OTC: {
    QUOTEID_EXPIRED: 5004,
    INTERNAL_SERVER_ERROR: 5005,
    UNABLE_TO_RETRIEVE_BALANCE: 5006,
    INSUFFICIENT_BALANCE: 5007,
    UNABLE_TO_ADD_BALANCE: 5008,
    FAIL_TO_QUERY_CREATE_USER_FEE_RECORD: 5009,
    FEECOLLECTIONERR: 5010,
    CONNERR: 5011,
    RECORD_NOT_FOUND_IN_REDIS: 5012,
    UNABLE_TO_ADD_BALANCE_FOR_USERID: 5013,
    UNABLE_TO_RE_ADD_BALANCE_FOR_USERID: 5014,
    UNABLE_TO_PERFORM_OTC_TRANSACTION: 5015,
    UPDATE_USER_FUND_ERROR: 5016,
    UNABLE_TO_CREATE_USER_FUND_LOG_FOR_USERID: 5017,
    UNABLE_TO_CREATE_USER: 5018,
    UNABLE_TO_CREATE_FEE_RECORD: 5019,
    UNABLE_TO_FETCH_QUOTATION: 5020,
    MIN_QTY: 5021,
    UNABLE_TO_GET_USD_MYR_RATE: 5022,
    UNABLE_TO_FETCH_PRICE: 5023,
    REQUEST_FAILED: 5024,
    KRAKEN_API_ERROR: 5025,
    UNABLE_TO_INITIALIZE_TO_ASSET_SPOT_BALANCE: 5026,
    UNABLE_TO_GET_QUOTE_TEMPORARILY: 5027,
    UNABLE_TO_FETCH_QUOTATION_INSUFFICIENT_LIQUIDITY: 5028,
    USERID_OR_TOKENID_MUST_BE_PROVIDED: 5029,
    INVALID_TOKEN_ID: 5030,
    INVALID_SCALE_OR_FAILED_TO_CONVERT_STRING_TO_INTEGER: 5031,
  },
  WALLET: {
    INVALID_LOGIN_CREDENTIALS: 8001,
    ACCOUNT_DISABLED_FOR_LOGIN: 8002,
    EMAIL_NOT_VERIFIED: 8003,
    UNABLE_TO_CREATE_INTERNAL_TRANSFER: 8004,
    WITHDRAW_REQUEST_FAILED: 8005,
    INSUFFICIENT_BALANCE_IN_VAULT: 8006,
    INVALID_REQUEST_ID: 8007,
    CRYPTO_NOT_FOUND: 8008,
    NETWORK_NOT_FOUND: 8009,
    INVALID_AMOUNT: 8011,
    GET_SPOT_BALANCE_ERROR: 8012,
    INSUFFICIENT_BALANCE: 8013,
    UNABLE_TO_DEDUCT_BALANCE: 8014,
    INVALID_COIN: 8015,
    DUPLICATED_USER_AND_NAME_COMBINATION: 8016,
    DUPLICATED_ADDRESS_NETWORK_AND_TAG_COMBINATION: 8017,
    ERROR_FETCHING_SUPPORTED_ASSETS: 8018,
    NO_SUPPORTED_ASSETS_FOR_NETWORK_FOUND: 8019,
    USER_DEPOSIT_ADDRESS_ALREADY_EXISTS: 8020,
    FAILED_TO_CREATE_FIREBLOCKS_ADDRESS: 8021,
    FAILED_TO_CREATE_FIREBLOCKS_VAULT: 8022,
    USER_ADDRESS_ALREADY_EXISTS: 8023,
    ADDRESS_BOOK_NOT_FOUND: 8024,
    API_SYMBOL_NOT_FOUND: 8025,
    GET_ACCOUNT_LIST_ERROR: 8026,
    INVALID_USER_ADDRESS: 8027,
    HASH_ALREADY_EXIST: 8028,
    ADD_SPOT_BALANCE_ERROR: 8029,
    INVALID_RECEIVER: 8030,
    USERS_WITHDRAWAL_IS_TEMPORARILY_DISABLED: 8031,
    SYMBOL_NOT_FOUND: 8032,
    TOKEN_NOT_FOUND: 8033,
    USER_DOES_NOT_HAVE_THE_REQUIRED_PLATFORM: 8034,
    SELF_TRANSFER_WITHIN_THE_SAME_PLATFORM_IS_NOT_ALLOWED: 8035,
    UNABLE_TO_CREATE_USER: 8036,
    UNABLE_TO_CREATE_FEE_RECORD: 8037,
    INVALID_ADDRESS_BOOK_ID: 8038,
    ADDRESS_BOOK_MATCHES_USERS_OWN_ADDRESS: 8039,
    DAILY_WITHDRAWAL_LIMIT_EXCEEDED: 8040,
    AMOUNT_LESS_THAN_FEE: 8042,
    ADDRESS_NOT_FOUND: 8043,
    FAILED_TO_PARSE_JWT: 8045,
    FAILED_TO_VERIFY_JWT: 8046,
    JWT_NOT_VALID: 8047,
    INVALID_SYMBOL: 8049,
    INVALID_NETWORK_NAME: 8050,
    UNABLE_TO_PROCESS_SWEEP_REQUEST: 8053,
    UNABLE_TO_RETRIEVE_BALANCE_FOR_GAS_FEES: 8056,
    FAIL_TO_FETCH_USERS_DETAILS: 8057,
    FAIL_TO_FETCH_USERS_BUSINESS_INFO: 8058,
    FAIL_TO_RETRIEVE_PLATFORM: 8059,
    WITHDRAWAL_CONFIRMATION_BY_ADMIN_FAILED: 8060,
    INVALID_OTP: 8061,
    INVALID_TOTP: 8062,
    FAIL_TO_CREATE_TOKEN: 8063,
    FAIL_TO_RETRIEVE_NUMBER_OF_RECENT_WITHDRAWAL: 8064,
    FAIL_TO_RETRIEVE_PUBLIC_KEY: 8065,
    UNABLE_TO_SUM_THE_TOTAL: 8067,
    UNABLE_TO_FETCH_TOKENS_FEES: 8068,
    WITHDRAWAL_NOT_FOUND: 8069,
    DEPOSIT_NOT_FOUND: 8070,
    REDIS_ERROR: 8071,
    FAIL_TO_CREATE_FIREBLOCKS_TRANSACTION: 8072,
    INVALID_ADDRESS: 8073,
    FIREBLOCKS_VAULT_ERROR: 8074,
    USER_ADDRESS_NOT_FOUND: 8075,
    FAIL_TO_CREATE_DEPOSIT: 8076,
    MISSING_EMAIL_AND_USER_ID: 8077,
    SELF_TRANSFER_DISABLED_HERE: 8078,
    ANNUAL_SEND_LIMIT_EXCEEDED: 8080,
    INVALID_ADDRESS_FORMAT: 8081
  },
};
