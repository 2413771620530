import CryptoJS from "crypto-js";
import countryList from "../public/data.json";
import moment from "moment";
import router from "@/router/index.js";
import { store } from "@/store";
const Compress = require("client-compress");
import { app } from "./main";
import Decimal from "decimal.js";

export default {
  methods: {
    navigateToRoute(routeName, params, query, isNewTab, hash) {
      const platformPreference = store.state.preferences.platform;
      const parent = platformPreference == 1 ? "Malaysia" : "Global";
      const prefix = store.getters["preferences/routePrefix"];

      const route = router.options.routes.find(
        (r) => r.name === (r.children ? parent : routeName)
      );

      let finalRoute =
        route?.children?.find((c) => c.name.includes(routeName)) || route;
      let finalRouteName =
        finalRoute &&
        !routeName.includes(prefix) &&
        (!finalRoute.meta || !finalRoute.meta.isCommon)
          ? `${prefix}-${routeName}`
          : routeName;

      const routeData = { name: finalRouteName, params, query, hash };

      if (isNewTab) {
        window.open(router.resolve(routeData).href, "_blank");
        return;
      }

      if (router.currentRoute.name !== routeData.name) {
        if (!store.state.auth.isLoggedIn && routeName === "MY-ExchangeView") {
          routeData.name = "MY-ExchangeView";
          let customRouteData = routeData;
          router.push(customRouteData);
        } else {
          router.push(routeData);
        }
      }
    },

    generateRouteLink(routeName, params, query, hash) {
      const platformPreference = store.state.preferences.platform;
      const parent = platformPreference == 1 ? "Malaysia" : "Global";
      const prefix = store.getters["preferences/routePrefix"];
      const route = this.$router.options.routes.find(
        (r) => r.name === (r.children ? parent : routeName)
      );

      let finalRoute =
        route?.children?.find((c) => c.name.includes(routeName)) || route;
      let finalRouteName =
        finalRoute &&
        !routeName.includes(prefix) &&
        (!finalRoute.meta || !finalRoute.meta.isCommon)
          ? `${prefix}-${routeName}`
          : routeName;
      return {
        name: finalRouteName,
        params,
        query,
        hash,
      };
    },

    filterCountryCode(name) {
      let country = countryList.filter((option) => {
        return option.countryName.toLowerCase().includes(name.toLowerCase());
      });

      return country.length > 0 ? country[0].countryShortCode : "";
    },

    filterCountryName(code) {
      let country = countryList.filter((option) => {
        return option.countryShortCode == code;
      });

      return country.length > 0 ? country[0].countryName : "";
    },

    openLinkInNewTab(link) {
      window.open(link, "_blank");
    },

    formatBoldText(text) {
      return text.replace(
        /%([^%]*)%/g,
        '<span class="fw-900 secondary-color-text">$1</span>'
      );
    },

    phoneFormatter(value) {
      if (value) {
        return (
          value.substring(0, 5) + "***" + value.substring(value.length - 3)
        );
      }
    },

    dateFormatter(value, format) {
      return moment(value).format(format);
    },

    leapYear(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    },

    removeSpacesAndDash(str) {
      if (str) {
        return str.replace(/[\s-]/g, "");
      }
    },

    extractAddress(qrCode) {
      if (qrCode.includes(":")) {
        let address = qrCode.split(":")[1];
        if (address.includes("?")) {
          return address.split("?")[0];
        } else {
          return address;
        }
      } else {
        return qrCode;
      }
    },

    storeProfileObject(
      id,
      name,
      email,
      referral_id,
      twoFa,
      phone,
      kyc_status,
      receive_news,
      isBusiness,
      secure_email,
      platform
    ) {
      let storedData = {
        id: id,
        name: name,
        email: email,
        referral_id: referral_id,
        twofa_enabled: twoFa,
        phone: phone,
        kyc_status: kyc_status,
        receive_news: receive_news,
        business: isBusiness,
        secure_email: secure_email,
        platform: platform,
      };

      return JSON.stringify(storedData);
    },

    encryptData(data) {
      return CryptoJS.AES.encrypt(
        data,
        process.env.VUE_APP_ENCRYPT_PASS
      ).toString();
    },

    decryptData(data) {
      let decryptedDataBytes = CryptoJS.AES.decrypt(
        data,
        process.env.VUE_APP_ENCRYPT_PASS
      );
      return CryptoJS.enc.Utf8.stringify(decryptedDataBytes).toString();
    },

    countDecimalPlaces(number) {
      if (!Number.isFinite(number)) {
        return 0;
      }

      const parts = number.toString().split(".");
      if (parts.length === 2) {
        return parts[1].length;
      }

      return 0;
    },

    numberSeparator(x, decimalPlaces) {
      let num = Number(x);
      if (num === 0) {
        return decimalPlaces !== undefined ? num.toFixed(decimalPlaces) : x;
      }

      if (x) {
        let [front, back = ""] = x.toString().split(".");
        front = front.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (decimalPlaces !== undefined) {
          back =
            back.length < decimalPlaces
              ? back + "0".repeat(decimalPlaces - back.length)
              : back.slice(0, decimalPlaces);
          back = decimalPlaces === 0 ? "" : "." + back;
        } else {
          back = back ? "." + back : "";
        }
        return front + back;
      }
    },

    numberTruncate(num, decPlaces = 0) {
      if (typeof num === "number" && num.toString().includes("e")) {
        num = num.toFixed(decPlaces + 1);
      }
    
      const numStr = num.toString();
      const index = numStr.indexOf(".");

      if (index === -1 || decPlaces === 0) {
        return Math.floor(num).toString();
      }

      const factor = Math.pow(10, decPlaces);
      const result = Math.floor(num * factor) / factor;
      
      let [front, back = ""] = result.toString().split(".");
      back =
        back.length < decPlaces
          ? back + "0".repeat(decPlaces - back.length)
          : back.slice(0, decPlaces);
      back = decPlaces === 0 ? "" : "." + back;

      return front + back;
    },

    // INSERT CHECKING
    insertMykad(e, value) {
      let char = e.keyCode;
      if (value.length < 12) {
        if (
          !(
            (char >= 48 && char <= 57) ||
            (char >= 96 && char <= 105) ||
            char == 8 ||
            char == 9 ||
            char == 46 ||
            (char >= 37 && char <= 40)
          )
        ) {
          e.preventDefault();
        }
      } else {
        if (
          char != 8 &&
          char != 9 &&
          char != 46 &&
          !(char >= 37 && char <= 40)
        ) {
          e.preventDefault();
        }
      }
    },

    insertNumber(e, maxLengthAfterDecimal) {
      let char = e.keyCode;
      const inputContainsDecimal = e.target.value.includes(".");

      if (
        !(
          (char >= 48 && char <= 57) ||
          (char >= 96 && char <= 105) ||
          char == 8 ||
          char == 9 ||
          char == 46 ||
          (char == 110 && !inputContainsDecimal) ||
          (char == 190 &&
            !inputContainsDecimal &&
            maxLengthAfterDecimal != 0) ||
          (char >= 37 && char <= 40) ||
          (e.ctrlKey &&
            (char === 65 || char === 67 || char === 86 || char === 88)) ||
          (e.metaKey &&
            (char === 65 || char === 67 || char === 86 || char === 88))
        )
      ) {
        e.preventDefault();
      }
    },

    insertPhone(e) {
      let char = e.keyCode;
      // let char = String.fromCharCode(e.keyCode);
      if (
        !(
          (char >= 48 && char <= 57) ||
          (char >= 96 && char <= 105) ||
          char == 8 ||
          char == 9 ||
          char == 46 ||
          (char >= 37 && char <= 40) ||
          (e.ctrlKey &&
            (char === 65 || char === 67 || char === 86 || char === 88)) ||
          (e.metaKey &&
            (char === 65 || char === 67 || char === 86 || char === 88))
        )
      ) {
        e.preventDefault();
      }
    },

    handleNumberInput(event, decimalPlaces) {
      const regex = /[^0-9.]/g;
      if (regex.test(event.target.value)) {
        event.target.value = "";
      }

      event.target.value = event.target.value.replaceAll(",", "");

      if (event.target.value.includes(".")) {
        const parts = event.target.value.split(".");
        const decimalPart = parts[1];

        if (decimalPart.length > decimalPlaces) {
          parts[1] = decimalPart.slice(0, decimalPlaces);
          event.target.value = parts.join(".");
          event.preventDefault();
        }
      }

      if (decimalPlaces == 0 && event.data == ".") {
        return;
      }

      if (event.target.value.includes(".")) {
        const parts = event.target.value.split(".");
        const decimalPart = parts[1];
        this.decimalLength = decimalPart.length;

        if (decimalPart.length <= this.decimalPlaces) {
          return true;
        }
      } else {
        return true;
      }
    },
    // END INSERT CHECKING

    // WALLET
    filterWallet(arr, symbol) {
      return arr.filter((item) => item.symbol == symbol);
    },

    setReceiveCryptoValue(symbol, item) {
      store.dispatch(
        `${
          store.state.preferences.platform == 1 ? "MY_wallet" : "wallet"
        }/setReceiveCrypto`,
        {
          symbol: symbol,
          network_name: item.network_name,
          is_tag: item.is_tag,
          is_utxo: item.is_utxo,
        }
      );
    },

    // KYC
    getFullAddress(address1, address2, postcode, city, state, country) {
      if (address2 != "") {
        if (address2.endsWith(",")) {
          address2 = address2.slice(0, -1);
        }
      } else {
        if (address1.endsWith(",")) {
          address1 = address1.slice(0, -1);
        }
      }

      let fullAddress =
        address1 +
        address2 +
        ", " +
        postcode +
        ", " +
        city +
        ", " +
        state +
        ", " +
        country.replaceAll(",", "");

      return fullAddress;
    },

    // EXCHANGE
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;

      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },

    groupByData(array, key) {
      const groups = {};

      array.forEach((item) => {
        const groupKey = item[key];
        if (!groups[groupKey]) {
          groups[groupKey] = true;
        }
      });

      const result = Object.keys(groups).map((groupKey) => {
        return { key: groupKey };
      });

      return result;
    },

    calcTotal(price, amount) {
      return price * amount;
    },

    calcFilled(origQty, execQty) {
      let deviceWidth = window.innerWidth;
      let decimalPlaces = deviceWidth > 768 ? 2 : 0;
      let number = ((execQty / origQty) * 100).toFixed(decimalPlaces);

      return isNaN(number) ? 0 : number;
    },

    calcAverage(cummulate, exec_qty) {
      let number = parseFloat(cummulate) / parseFloat(exec_qty);
      return isNaN(number) ? 0 : number;
    },

    getTodayTime(isStart) {
      return isStart
        ? moment().startOf("day").unix()
        : moment().endOf("day").unix();
    },

    getStartDate(daysBeforeToday) {
      return moment().subtract(daysBeforeToday, "days").unix();
    },

    getTickSize(number) {
      let initialNumber = 1 / Math.pow(10, number);
      let count = 5;
      let dropdownValue = {};

      for (let i = 0; i < count; i++) {
        let value = initialNumber * Math.pow(10, i);
        dropdownValue[i] = value;
      }

      return dropdownValue;
    },

    filterScale(pairInfo, txPair) {
      let pair = [];
      // if (typeof pairInfo === "object") {
      if (pairInfo.txpair) {
        pair.push(pairInfo);
        // }
        // else {
        //   pairInfo.map((item) => {
        //     if (item.txpair === txPair) {
        //       pair.push(item);
        //     }
        //   });
        // }
      } else {
        pair = pairInfo.filter((item) => item.txpair === txPair);
      }

      return pair.length > 0
        ? pair[0]
        : {
            disp_price_scale: 8,
            disp_qty_scale: 8,
          };
    },

    // WALLET
    returnStatusColor(value) {
      if (value) {
        let status = value.toLowerCase();
        return status ===
          app.config.globalProperties.$t("Status.completed").toLowerCase()
          ? "bg-primary-color"
          : status ===
            app.config.globalProperties.$t("Status.pending").toLowerCase()
          ? "bg-orange"
          : "bg-error";
      }
    },

    returnStatusTextColor(value) {
      if (value) {
        let status = value.toLowerCase();
        return status ===
          app.config.globalProperties.$t("Status.pending").toLowerCase()
          ? "orange-text"
          : status ===
              app.config.globalProperties
                .$t("Status.completed")
                .toLowerCase() ||
            status ===
              app.config.globalProperties.$t("Status.approved").toLowerCase()
          ? "primary-color-text"
          : "fail-text";
      }
    },

    returnStatusIcon(value) {
      if (value) {
        let status = value.toLowerCase();

        return status === "approved"
          ? "mdi-check-circle"
          : status === "pending"
          ? "mdi-dots-horizontal-circle"
          : "mdi-information";
      }
    },

    getTokenData(data, symbol) {
      let filtered = data.filter((item) => {
        return item.symbol === symbol;
      });

      return filtered.length > 0
        ? JSON.parse(JSON.stringify(filtered[0]))
        : { Image: "" };
    },

    async compressImage(file) {
      const options = {
        targetSize: 2,
        quality: 0.75,
        minQuality: 0.5,
        qualityStepSize: 0.1,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
        throwIfSizeNotReached: false,
      };
      const compress = new Compress(options);
      let files = [...file];
      let result = await compress
        .compress(files)
        .then((conversions) => {
          const { photo, info } = conversions[0];
          return photo;
        })
        .catch((e) => {
          console.log(e.message);
        });

      return result;
    },

    // FILE UPLOAD
    fileUploadProcess(file) {
      return new Promise((resolve, reject) => {
        const compressType = ["image/jpg", "image/jpeg", "image/png"];
        let result = "";
        let compressed = "";
        const fr = new FileReader();

        // fr.onloadstart = () => this.changeStatus('Start Loading');
        // fr.onload = () => this.changeStatus('Loaded');
        fr.onloadend = async (e) => {
          // this.changeStatus('Finished Loading!');
          for (let i = 0; i < compressType.length - 1; i++) {
            if (file.type.toLowerCase() == compressType[i]) {
              let blob = await this.urlToFile(
                e.target.result,
                file.name,
                file.type
              );

              await this.imageCompress(blob)
                .then((result) => {
                  compressed = result;
                })
                .catch((e) => {
                  reject(new Error(e));
                });
              result = await this.convertBased64(compressed.data);
              break;
            } else {
              result = e.target.result;
            }
          }

          resolve({ data: result });
        };
        // fr.onprogress = this.setProgress;
        fr.onerror = (e) => reject(new Error(e.target.error.name));
        fr.onabort = () =>
          reject(
            new Error(app.config.globalProperties.$t("Kyc.fileUploadInterrupt"))
          );
        fr.readAsDataURL(file);
      });
    },

    async urlToFile(url, filename, mimeType) {
      const res = await fetch(url);
      const buf = await res.arrayBuffer();
      return new File([buf], filename, { type: mimeType });
    },

    convertBased64(blob) {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },

    imageCompress(file) {
      return new Promise((resolve, reject) => {
        const options = {
          targetSize: 2,
          quality: 0.75,
          minQuality: 0.5,
          qualityStepSize: 0.1,
          maxWidth: 1920,
          maxHeight: 1920,
          resize: true,
          throwIfSizeNotReached: false,
        };

        const compress = new Compress(options);

        compress
          .compress([file])
          .then(async (conversions) => {
            const { photo } = conversions[0];
            resolve(photo);
          })
          .catch((e) => {
            console.log(e);
            reject(new Error(e.message));
          });
      });
    },

    getDashedPair(pair) {
      let pairInfo =
        this.$store.getters["preferences/routePrefix"] === "GB"
          ? this.$store.state.orderbook.pairs
          : this.$store.state.MY_orderbook.pairs;
      let selectedPair = pairInfo.filter((info) => info.txpair === pair);

      return selectedPair.length > 0
        ? selectedPair[0].base + "/" + selectedPair[0].quote
        : "";
    },

    filterBuySellToken(otcList, balanceList) {
      const availableList = balanceList.filter((e) =>
        otcList.crypto.some((obj) => obj.symbol === e.symbol)
      );

      return availableList;
    },

    addDecimals(a, b) {
      let total = Decimal.add(a, b);
      return total.toNumber();
    },

    removeZeroAmount(amt) {
      let data = amt.toString();
      if (data.length > 1) {
        if (data.charAt(0) === "0" && data.charAt(1) !== ".") {
          data = data.slice(1, data.length);
        }
      }

      return data;
    },

    errorCheck(error) {
      if (
        error.code &&
        app.config.globalProperties.$t(`Error.${error.code}`) !==
          `Error.${error.code}`
      ) {
        return app.config.globalProperties.$t(`Error.${error.code}`);
      } else {
        return error.error || error.message;
      }
    },

    // async returnToMerchant() {
    //   let redirect = store.state.payment.callback_url;
    //   const params = {
    //     status:       store.state.payment.status == "completed" ? "completed" : "cancelled",
    //     request_id: store.state.payment.request_id,
    //     reference_id: store.state.payment.reference_id,
    //   };
    //   return await fetch(redirect, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(params),
    //   }).then(response => {
    //     console.log(response)
    //     if (response.redirected) {
    //       window.location.href = response.url;
    //     } else if (response.status === 302) {
    //       const redirectUrl = response.headers.get('Location');
    //       window.location.href = redirectUrl;
    //     } else {
    //       return response.json();
    //     }
    //   });
    // },

    async returnToMerchant() {
      let redirect = store.state.payment.callback_url;
      const params = {
        status:
          store.state.payment.status === "completed"
            ? "completed"
            : "cancelled",
        request_id: store.state.payment.request_id,
        reference_id: store.state.payment.reference_id,
      };

      const form = document.createElement("form");
      form.method = "POST";
      form.action = redirect;

      Object.keys(params).forEach((key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = params[key];
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    },
  },
};
