<template>
    <div class="custom-pincode-container">
        <v-otp-input
            :modelValue="inputValue"
            :length="6"
            type="number"
            autofocus
            :error="errorMessage ? true : false"
            :disabled="disabled"
            @update:modelValue="handleInput"
            @keydown="insertNumber($event)"
            variant="plain"
        />

        <div class="error-message">{{ errorMessage }}</div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: { type: String },
        errorMessage: { type: String, default: "" },
        disabled: { type: Boolean, default: false }
    },

    model: {
        prop: "value",
        event: "update"
    },

    data() {
        return {
            inputValue: "",
        }
    },

    watch:{
        modelValue: {
            handler(){
                this.inputValue = this.modelValue;
            }
        },
    },

    methods: {
        handleInput(event) {
            this.$emit("clearError");
            
            event = event.replaceAll(" ", "");
            event = event.replace(/\D/g, "");
            
            if(event.length > 6) {
                event = event.substring(0,6);
            }
            
            this.$emit("update:modelValue", event);
        },
    }
}
</script>

<style>
.custom-pincode-container {
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    text-align: center;
}

.custom-pincode-container .v-otp-input__content {
    gap: 16px;
    max-width: 100% !important;
}

.custom-pincode-container .v-otp-input .v-field {
    min-height: 56px !important;
    min-width: 56px !important;
    background-color: var(--gray20) !important;
    border-radius: 8px;
}

.custom-pincode-container .v-otp-input__field:hover  {
    border: 2px solid var(--secondary-color);
    border-radius: 8px;
}

.custom-pincode-container .error-message {
    margin-top: 10px;
}

@media only screen and (max-width: 468px) {
    .custom-pincode-container { 
        width: 320px;
    }

    .custom-pincode-container .v-otp-input__content {
        gap: 8px;
    }

    .custom-pincode-container .v-otp-input .v-field {
        min-height: 50px !important;
        min-width: 50px !important;
    }
}
</style>