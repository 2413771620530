<template>
  <div
    class="navigation-btn-container"
    :class="[
      !isClickable ? 'navigation-btn-nonclickable' : '',
      !subtitle ? 'align-items-center' : '',
      isSelected ? 'navigation-btn-container-selected' : '',
      isFaded ? 'non-clickable' : '',
    ]"
    @click="isClickable ? $emit('action') : null"
  >
    <div class="navigation-btn-img">
      <img
        :src="require(`@/assets/images/${icon}`)"
        :width="iconSize"
        v-if="icon != '' && !isBank"
      />

      <!-- FOR BANK NAVIGATION -->
      <img
        :src="
          `${icon}` != ''
            ? `${icon}`
            : require('@/assets/images/wallet/bank-icon.png')
        "
        :width="iconSize"
        v-else-if="isBank"
      />
      <div class="red-dot" v-if="isRedDot"></div>
    </div>

    <div class="navigation-btn-content">
      <div class="navigation-btn-title d-flex flex-row">
        <div>{{ title }}&nbsp;</div>
        <span v-if="recommended" class="recommendedBar">{{$t("Profile.recommended")}}</span>
        <span :class="statusColor" v-if="status"> ({{ status }})</span>

        <slot name="title"></slot>
      </div>

      <div 
        class="navigation-btn-title primary-color-text" 
        v-if="keyId"
      >
        {{ $t("Profile.keyId") }}: {{ keyId }}
      </div>

      <div
        class="navigation-btn-subtitle"
        :class="[
          isCapitalizeSub ? 'text-capitalize' : '',
          !isDefaultSub ? subtitleColor : '',
        ]"
        v-if="!isKyc"
      >
        {{ subtitle }}
      </div>

      <div class="navigation-btn-subtitle kyc-subtitle" v-else>
        <span
          class="text-capitalize"
          :class="!isDefaultSub ? subtitleColor : ''"
        >
          {{ kycStatus }}
        </span>
        <span
          v-for="(subs, index) in subtitles"
          :key="index"
          :class="!isDefaultSub ? subtitleColor : ''"
        >
          {{
            kycStatus === "rejected"
              ? subtitles.length > 1
                ? `${$t("Kyc.reason")} ${index + 1} : ${subs}`
                : `${$t("Kyc.reason")} : ${subs}`
              : ""
          }}
        </span>
      </div>

      <div class="navigation-btn-subtitle" v-if="tag">
        {{ $t("wallet.tag") }}: {{ tag }}
      </div>

      <div class="navigation-btn-network" v-if="network">
        {{ network }}
      </div>
    </div>

    <v-icon v-if="isRightArrow" class="align-self-center">mdi-chevron-right</v-icon>
    <v-icon :style="{ color: `${iconColor} !important` }" v-if="rightIcon">
      {{ rightIcon }}
    </v-icon>
    <slot name="deleteApiKey"></slot>
    <div class="navigation-tertiary-btn" v-if="isCloseBtn">
      <button @click="$emit('delete')" class="tertiary-btn">
        <img src="@/assets/images/modal/close-circle.png" width="22px"/>
      </button>
    </div>
    <div class="navigation-tertiary-btn" v-if="isEditButton">
      <button class="tertiary-btn" @click="$emit('edit')">
        {{ $t("Kyc.editInfo") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String },
    bank_image: { type: String, default: "" },
    iconSize: { type: Number, default: 38 },
    title: { type: String },
    subtitle: { type: String },
    status: { type: String },
    statusColor: { type: String, default: "secondary-color-text" },
    tag: { type: String },
    keyId: { type: String },
    network: { type: String },
    item: { type: Object },
    isClickable: { type: Boolean, default: true },
    isCapitalizeSub: { type: Boolean, default: false },
    isDefaultSub: { type: Boolean, default: true },
    subtitleColor: { type: String, default: "" },
    isPrimarySub: { type: Boolean, default: false },
    isRedDot: { type: Boolean, default: false },
    isRightArrow: { type: Boolean, default: true },
    rightIcon: { type: String, default: "" },
    isEditButton: { type: Boolean, default: false },
    isKyc: { type: Boolean, default: false },
    kycStatus: { type: String },
    isSelected: { type: Boolean, default: false },
    isFaded: { type: Boolean, default: false },
    iconColor: { type: String, default: "var(--header-text)" },
    isBank: { type: Boolean, default: false },
    isCloseBtn: { type: Boolean, default: false },
    recommended: { type: Boolean, default: false },
  },

  data() {
    return {
      subtitles: "",
    };
  },

  mounted() {
    if (this.subtitle) {
      try {
        this.subtitles = JSON.parse(this.subtitle.toString());
      } catch (e) {
        this.subtitles = this.subtitle.split();
      }
    }
  },

  methods: {
    action() {
      if (this.item) {
        this.$emit("action", this.item);
      } else {
        this.$emit("action");
      }
    },
  },
};
</script>

<style>
  .recommendedBar {
     color: var(--black);
     border-radius: 10px;
     padding: 3px 5px;
     font-size: 13px;
     background: var(--tertiary50);
  }
</style>
